import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShoopyStores,
  clearShoopyStores,
  setShoopyStoresPerPage,
  setShoopyStoresInputKeyword,
  setShoopyStoresSearchKeyword,
  setShoopyStoresCurrentPage,
  setShoopyStoresMaxPageSoFar,
  updateShoopyStore,
} from "../../redux/shoopystores/shoopyStoreActions";
import { SHOOPY_STORES_PER_PAGE } from "../../utils/utils";
import Toast from "../../components/layout/Toast";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
  rowFlexCenterAll,
} from "../../styles/common";
import PageLoader from "../../components/layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Moment from "react-moment";
import axios from "axios";
import LoginIcon from "../../icons/LoginIcon";
import ViewStore from "./components/ViewStore";

const ShoopyStores = () => {
  const classes = useStyles();
  const storeState = useSelector((state) => state.shoopystores);
  const stores = storeState.stores;
  const storesPerPage = storeState.storesPerPage;
  const currentPage = storeState.currentPage;
  const searchKeyword = storeState.searchKeyword;
  const inputKeyword = storeState.inputKeyword;
  const [anchoredStore, setAnchoredStore] = useState(null);
  const [openStore, setOpenStore] = useState(false);
  const [toast, setToast] = useState(false);

  let startIndex = currentPage * storesPerPage;
  let endIndex =
    startIndex + storesPerPage < stores.length
      ? startIndex + storesPerPage
      : stores.length;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!storesPerPage) {
      dispatch(setShoopyStoresPerPage(SHOOPY_STORES_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [storesPerPage]);

  const onSearch = (keyword) => {
    if (keyword) {
      dispatch(setShoopyStoresMaxPageSoFar(0));
      dispatch(setShoopyStoresCurrentPage(0));
      dispatch(fetchShoopyStores(0, keyword, storesPerPage));
    }
  };

  const onStoreViewClose = () => {
    setOpenStore(false);
    setAnchoredStore(null);
  };

  const onStoreChange = (upStore) => {
    dispatch(updateShoopyStore(upStore));
  };

  const onLoginClick = (storeId) => {
    let accessToken = localStorage.getItem("access_token");
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/partner-users/login-token?store-id=${storeId}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        let link = `${process.env.REACT_APP_WEB}/login?token=${response.data.payload}`;
        window.open(link);
      })
      .catch((error) => {
        setToast(true);
      });
  };

  if (storeState.progress && stores.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Stores" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Stores" }]} />
            </div>

            <div style={{ position: "relative", width: "100%" }}>
              <div className={layoutBodyBox}>
                <div className={classes.tableHeader}>
                  <div className={classes.search}>
                    <SearchIcon />
                    <input
                      type="text"
                      placeholder="Search by Phone Number, Store Name Or Slug"
                      className={classes.searchBox}
                      value={inputKeyword}
                      onChange={(e) => {
                        dispatch(setShoopyStoresInputKeyword(e.target.value));
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (inputKeyword.trim() !== "") {
                            dispatch(
                              setShoopyStoresSearchKeyword(inputKeyword)
                            );
                            onSearch(inputKeyword.trim());
                          }
                        }
                        if (e.key === "Escape") {
                          dispatch(setShoopyStoresInputKeyword(""));
                          dispatch(setShoopyStoresSearchKeyword(""));
                          dispatch(setShoopyStoresMaxPageSoFar(0));
                          dispatch(setShoopyStoresCurrentPage(0));
                          dispatch(clearShoopyStores());
                        }
                      }}
                    />
                  </div>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead className={classes.tablehead}>
                      <TableRow className={classes.headrow}>
                        <TableCell style={{ width: "15%" }}>
                          Store Name
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>
                          Created At
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>Domain</TableCell>
                        <TableCell style={{ width: "15" }}>Slug</TableCell>
                        <TableCell style={{ width: "15%" }}>Plan</TableCell>
                        <TableCell style={{ width: "15%" }}>Mobile</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tablebody}>
                      {stores
                        .slice(startIndex, endIndex)
                        .map((tableitem, index) => (
                          <TableRow
                            key={tableitem.store_id}
                            style={{
                              borderBottom:
                                (index + 1) % storesPerPage === 0
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            <TableCell>
                              <span
                                style={{
                                  color: "#1641DB",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAnchoredStore(tableitem);
                                  setOpenStore(true);
                                }}
                              >
                                {tableitem.store_name}
                              </span>
                            </TableCell>
                            <TableCell>
                              <Moment format="DD-MMM-YYYY">
                                {tableitem.created_at}
                              </Moment>
                            </TableCell>
                            <TableCell style={{ textTransform: "none" }}>
                              <a
                                href={`https://${tableitem.domain}/${
                                  tableitem.domain.endsWith(".shoopy.in")
                                    ? tableitem.slug
                                    : ""
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {`https://${tableitem.domain}/${
                                  tableitem.domain.endsWith(".shoopy.in")
                                    ? tableitem.slug
                                    : ""
                                }`}
                              </a>
                            </TableCell>
                            <TableCell style={{ textTransform: "none" }}>
                              {tableitem.slug}
                            </TableCell>
                            <TableCell>{tableitem.store_plan}</TableCell>
                            <TableCell>{tableitem.mobile}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <Button
                                className={classes.loginbtn}
                                variant="outlined"
                                disabled={tableitem.store_id === 18147}
                                onClick={() => onLoginClick(tableitem.store_id)}
                              >
                                <LoginIcon />
                                &nbsp;&nbsp;&nbsp;Login
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {searchKeyword !== "" && stores.length === 0 && (
                    <div
                      style={{
                        height: 68 * 8 - 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginTop: -34,
                        }}
                      >
                        No Store Found
                      </p>
                    </div>
                  )}
                  {searchKeyword === "" && (
                    <div
                      className={rowFlexCenterAll}
                      style={{
                        height: 60 * storesPerPage - 4,
                      }}
                    ></div>
                  )}
                  {storeState.progress && <LinearProgress color="secondary" />}
                </TableContainer>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {anchoredStore && openStore && (
        <ViewStore
          store={anchoredStore}
          onClose={onStoreViewClose}
          onChange={onStoreChange}
        />
      )}
      <Toast
        severity="error"
        open={toast}
        close={() => setToast(false)}
        message={"Something went wrong, please try again!"}
      />
    </Fragment>
  );
};
export default ShoopyStores;
